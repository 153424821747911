

<section class="portfolio-area portfolio-area-two pt-50">
    <div class="container">
        <h2 class="section-category-title">
          فيدوهات وكالتنا
          </h2>
          

        <div class="row">
         

        <ng-container *ngFor="let videoItem of videosData">
            <div class="col-sm-6 col-lg-4">
                <div class="team-item">
                    <a
                    routerLink="/video-details/{{
                        videoItem.VideoId
                    }}"
                    >
                    <div class="category-video">
                        <img
                        style="height: 17rem;"
                        class="img-fluid"
                        [src]="prefixUrl+videoItem?.ImagePath"
                        alt="Blog"
                    />
                    </div>
                    <div class="team-inner video-item-text">
                        
                        <h3>
                            <a
                                class="video-title"
                                routerLink="/video-details/{{
                                    videoItem._id
                                }}"
                                >{{ videoItem.VideoTitle }}
                            </a>
                        </h3>
                    </div>
                </a>
                </div>
            </div>
        </ng-container>
        </div>

        <!--<div class="case-pagination">
            <ul>
                <li><a routerLink="/case-study"><i class="icofont-simple-left"></i></a></li>
                <li><a routerLink="/case-study">1</a></li>
                <li><a routerLink="/case-study">2</a></li>
                <li><a routerLink="/case-study">3</a></li>
                <li><a routerLink="/case-study"><i class="icofont-simple-right"></i></a></li>
            </ul>
        </div>-->
    </div>
</section>